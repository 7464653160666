export default {
  siteTitle: 'Get bonus',
  blogerName: 'RUSTY',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@RUSTYOFC/streams'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/rustyofc/'
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1go-irit.com/c69cc1807',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irit01.com/c6588f7cb',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-ircp12.com/c9662c22d',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irit01.com/c4ac1b71e',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs10.com/ca113b1e3',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'izzi',
      url: 'https://iz-ir13.com/cd1471edb',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/cbacbf4d0',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'fresh',
      url: 'https://frsh-ir01.com/caba90ff1',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'jet',
      url: 'https://jtcaz-intfr02.com/c9d3c4b98',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>RUSTY</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'RUSTY',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
};
